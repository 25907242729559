import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private get_server_endpoint_url = '/api/Common/GetServerEndpoint'
  private url_validation = '/api/Common/IsUrlValid'
  constructor(private httpClient: HttpClient) {}

  public GetServerEndpointObs(): Observable<string> {
    const hdrs = new HttpHeaders({
      'Content-Type': 'text',
      'Accept-Language': 'en',
    })
    return this.httpClient.get<string>(this.get_server_endpoint_url, {
      headers: hdrs,
    })
  }

  public IsUrlValidObs(uriName: string): Observable<string> {
    const hdrs = new HttpHeaders({
      'Content-Type': 'text',
      'Accept-Language': 'en',
    })
    return this.httpClient.get<string>(
      this.url_validation + '/?uriName=' + uriName,
      {
        headers: hdrs,
      },
    )
  }
}
