export class EventSubscription {
  constructor(numberOfRetries:number,apiKey:string) {
    this.eventTypes = []
    this.numberOfRetries = numberOfRetries
    this.apiKey = apiKey
  }
  numberOfRetries: number
  apiKey:string
  eventTypes: EventType[]
}
export class EventType {
  constructor() {
    this.eventTypeStatus = []
  }
  url: string
  eventTypeTitle: string
  eventTypeStatus: string[]
}
