import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthenticationModule } from 'src/app/modules/authentication/authentication.module'
import { WebhookModule } from 'src/app/modules/webhook/webhook.module'
import { LoginLayoutComponent } from './login-layout/login-layout.component'
import { MainLayoutComponent } from './main-layout/main-layout.component'
import { LoginActivate } from "src/app/core/services/login-activate.service";

const routes: Routes = [
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => AuthenticationModule,
        canActivate: [LoginActivate],
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'webhooks',
        loadChildren: () => WebhookModule,
        canActivate: [LoginActivate],
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
