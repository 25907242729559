import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { EventSubscription } from '../models/eventSubscription'

@Injectable({
  providedIn: 'root',
})
export class WebhookService {
  private get_event_subscriptions_url =
    '/api/AzureManagement/GetEventSubscriptionsListByResource'
  private udpate_subscriptions_url = '/api/AzureManagement/UpdateSubscriptions'
  constructor(private httpClient: HttpClient) {}

  public GetEventSubscriptions(): Observable<EventSubscription> {
    const hdrs = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.httpClient.get<EventSubscription>(
      this.get_event_subscriptions_url,
      {
        headers: hdrs,
      },
    )
  }

  public UpdateSubscriptions(list): Observable<any> {
    const hdrs = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    var formData = new FormData()
    formData.append('json', list)
    return this.httpClient.post(
      this.udpate_subscriptions_url,
      JSON.stringify(list),
      {
        headers: hdrs,
      },
    )
  }
}
