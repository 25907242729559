import { Injectable } from "@angular/core";
import { SpinnerVisibilityService } from "ng-http-loader";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  public isLoading = new BehaviorSubject(false);

  constructor(private spinner: SpinnerVisibilityService) {
    this.isLoading.subscribe((value) => {
      if (value) this.spinner.show();
      else this.spinner.hide();
    });
  }
}
