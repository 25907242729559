import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationService } from 'src/app/core/services/authentication.service'
import { CommonService } from 'src/app/core/services/common.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  userName_ = ""
  password_ = ""

  loginForm: FormGroup
  loading = false
  submitted = false
  returnUrl: string
  tokenValidation: boolean

  userName: FormControl
  password: FormControl

  loginResult: string = 'ok'

  hasError = () => this.loginResult?.replace('ok', '').length > 1

  get login() {
    return this.loginForm.controls
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
  ) {
    this.checkToken();
  }

  checkToken(): void {
    this.authenticationService.isTokenValid().subscribe((data) => {
      if (data) {
        this.router.navigate([this.returnUrl]);
      }
    });
  }
  ngOnInit(): void {
    this.getServerEndpint()
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'] || '/webhooks'

    this.userName = new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
    ])
    this.password = new FormControl('', Validators.required)

    this.loginForm = new FormGroup({
      username: this.userName,
      password: this.password,
    })
  }

  onSubmit() {
    this.submitted = true
    // stop here if form is invalid
    if (this.loginForm.invalid) return

    this.loading = true
    this.authenticationService
      .login(this.login.username?.value, this.login.password?.value)
      .subscribe(
        (data) => {
          this.loginResult = data?.error_description ?? 'ok'
          if (this.loginResult == 'ok') this.router.navigate([this.returnUrl])
          this.loading = false
        },
        (err) => {
          this.loginResult = err.message.toString().replace('Error: ', '')
          this.loading = false
        },
      )
  }
  endpoint = null
  getServerEndpint() {
    this.commonService.GetServerEndpointObs().subscribe(
      (data) => {
        console.log(data)
        if (data.length > 0) this.endpoint = data
      },
      (err) => {
        this.endpoint = err.error.text
      },
    )
  }

  get resetPasswordLink() {
    let url = '/account/forgotpassword'
    return this.endpoint + url
  }
}
