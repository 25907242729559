import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

//#region "Loader"
import { LoaderInterceptor } from "./core/interceptors/loader-interceptor.service";
import { NgHttpLoaderModule } from "ng-http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
//#endregion "Loader"

//#region "Componenets"
import { AppComponent } from "./app.component";
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { LayoutModule } from "./shared/layout/layout.module";
//#endregion "Componenets"

import { ClipboardModule } from "ngx-clipboard";
@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    LayoutModule,
    NgHttpLoaderModule,
    BrowserAnimationsModule,
    ClipboardModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
