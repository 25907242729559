import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ClipboardService } from 'ngx-clipboard'
import { WebhookService } from 'src/app/core/services/webhook.service'
import {
  EventSubscription,
  EventType,
} from '../../../../core/models/eventSubscription'
@Component({
  selector: 'app-register-webhook',
  templateUrl: './register-webhook.component.html',
  styleUrls: ['./register-webhook.component.css'],
})
export class RegisterWebhookComponent implements OnInit {
  alertClass = 'alert-danger'
  alertMessage = 'error'
  apiKey: string = ''

  webhookLists = [
    {
      id: 'OrderStatus',
      title: 'Delivery Status',
      visible: true,
      required: true,
      statuses: [
        {
          id: 'All',
          title: 'All',
          values: ['Delivered', 'UnDelivered', 'Accepted', 'HeadToPickup', 'AtPickup', 'PickedUp', 'OnGoing', 'OrderNotPickedUp'],
          checked: false,
        },
        {
          id: 'Accepted',
          title: 'Accepted',
          values: ['Accepted'],
          checked: false,
        },
        {
          id: 'HeadToPickup',
          title: 'Head To Pickup',
          values: ['HeadToPickup'],
          checked: false,
        },
        {
          id: 'AtPickup',
          title: 'At Pickup',
          values: ['AtPickup'],
          checked: false,
        },
        {
          id: 'PickedUp',
          title: 'Picked Up',
          values: ['PickedUp'],
          checked: false,
        },
        {
          id: 'OnGoing',
          title: 'OnGoing',
          values: ['OnGoing'],
          checked: false,
        },
        {
          id: 'DeliveryEvent',
          title: 'Delivery Event',
          values: ['Delivered', 'UnDelivered'],
          checked: false,
        },
        {
          id: 'OrderNotPickedUp',
          title: 'Not PickedUp',
          values: ['OrderNotPickedUp'],
          checked: false,
        }
      ],
    },
    {
      id: 'OrderManifest',
      title: 'Delivery Manifest',
      visible: true,
      required: false,
      statuses: [
        {
          id: 'All',
          title: 'All',
          values: ['UserCancelled'],
          checked: false,
        },
        {
          id: 'Cancelled',
          title: 'Cancelled',
          values: ['UserCancelled'],
          checked: false,
        }
      ],
    },
    {
      id: "ScanEvent",
      title: "Scan Event",
      visible: true,
      required: false,
      statuses: [
        {
          id: "All",
          title: "All",
          values: ["Inbound", "Pickup", "Drop", "RTT"],
          checked: false,
        },
        {
          id: "Inbound",
          title: "Inbound",
          values: ["Inbound"],
          checked: false,
        },
        {
          id: "Pickup",
          title: "Pickup",
          values: ["Pickup"],
          checked: false,
        },
        {
          id: "Drop",
          title: "Drop",
          values: ["Drop"],
          checked: false,
        },
        {
          id: "RTT",
          title: "RTT",
          values: ["RTT"],
          checked: false,
        },
      ],
    },
  ]
  retries: number[]
  subscriptionForm: FormGroup

  form(name: string) {
    return this.subscriptionForm.controls[name + '_Input']
  }

  constructor(
    private webhookService: WebhookService,
    private clipboardService: ClipboardService,
  ) {}

  ngOnInit(): void {
    this.FormBuilder()
    this.LoadData()
  }
  counter(start: number,end:number) :number[]{
    var array =  Array.from(Array(end-start + 1)).map((_, i) => i + start);
    return array;
  }
  FormBuilder(): void {
    this.subscriptionForm = new FormGroup({
      Retry_Input: new FormControl('5', [
        Validators.required,
        Validators.min(1),
        Validators.max(30),
      ]),
      ApiKey_Input: new FormControl('', [
        Validators.required,
        Validators.maxLength(4000)
      ]),
    })

    this.webhookLists.forEach((eventType) => {
      var formControl = new FormControl('', {
        validators: [Validators.minLength(5), Validators.maxLength(2000)],
      })
      if (eventType.required) formControl.addValidators(Validators.required)
      this.subscriptionForm.addControl(`${eventType.id}_Input`, formControl)
    })
  }

  LoadData(): void {
    this.webhookService.GetEventSubscriptions().subscribe((data) => {
      if (data) {
        this.subscriptionForm.controls['Retry_Input'].setValue(
          `${data.numberOfRetries}`,
        )
        //this.apiKey = data.apiKey ?? ''
        this.setKey(data.apiKey)

        this.webhookLists.forEach((item) => {
          var subscription = data.eventTypes.find(
            (a) => a.eventTypeTitle == item.id,
          )
          if (subscription) {
            var control = this.form(subscription.eventTypeTitle)
            control.setValue(subscription.url)

            var allStatus = item.statuses.find((a) => a.id == 'All')
            var missing = allStatus.values.filter(
              (item) => subscription.eventTypeStatus.indexOf(item) < 0,
            )
            allStatus.checked = missing.length == 0
            subscription.eventTypeStatus.forEach((status) => {
              var check = item.statuses.find(
                (a) => a.values.includes(status) && a.id != 'All',
              )
              if (check) check.checked = true
            })
          }
        })
      }
    })
  }
  getWebhookNode(eventType: string, statusId: string) {
    var event = this.webhookLists.find((i) => i.id === eventType)
    return event.statuses.find((a) => a.id == statusId)
  }
  onSubmit(): void {
    this.alertClass = 'd-none'
    if (this.subscriptionForm.status != 'VALID') return
    var numberOfRetries = this.subscriptionForm.controls['Retry_Input'].value
    var postObject: EventSubscription = new EventSubscription(
      numberOfRetries,
      this.getKey(),
    )
    this.webhookLists.forEach((event) => {
      var eventType: EventType = new EventType()
      eventType.eventTypeTitle = event.id
      var control = this.form(event.id)
      eventType.url = control.value
      event.statuses.forEach((status) => {
        if (status.checked)
          eventType.eventTypeStatus = eventType.eventTypeStatus.concat(
            status.values,
          )
      })
      postObject.eventTypes.push(eventType)
    })
    this.webhookService.UpdateSubscriptions(postObject).subscribe(
      (data) => {
        if (data.isSuccessStatus) {
          this.alertClass = 'alert-success d-block'
        } else {
          this.alertClass = 'alert-danger d-block'
        }
        this.alertMessage = data.message
      },
      (err) => {
        this.alertClass = 'alert-danger d-block'
        this.alertMessage = 'Server Error.'
        console.log(err)
      },
    )
  }

  statusCheckedEvent(event, itemId, statusId) {
    var isChecked = event.target.checked
    // Check all statuses if 'ALL' got checked
    if (statusId == 'All') {
      if (isChecked)
        this.webhookLists
          .filter((a) => a.id == itemId)
          .forEach((element) => {
            element.statuses.forEach((status) => {
              if (status.id != 'All') {
                this.getWebhookNode(itemId, status.id).checked = true
              }
            })
          })
    }
    //At least one might be checked
    if (!isChecked) {
      var classOBJs = Array.from(
        document.getElementsByClassName(`${itemId}_Checkbox_Class`),
      )
      var isCheckedAvailable = false
      classOBJs.forEach((obj) => {
        if ((obj as HTMLInputElement).checked) isCheckedAvailable = true
      })
      if (!isCheckedAvailable) {
        var split = `${event.target.id}`.split('_')
        this.getWebhookNode(split[1], split[0]).checked = true
        event.target.checked = true
      }
    }
  }
  generateNewKey(): void {
    this.setKey(Guid.newGuid())
  }
  setKey(key: string): void {
    this.form('ApiKey').setValue(key)
    this.apiKey = key
  }
  getKey(): string {
    console.log(this.form('ApiKey').value)
    return this.form('ApiKey').value
  }

  isCopied: boolean = false
  copyToClipboard() {
    this.clipboardService.copy(this.apiKey)
    this.isCopied = true
  }
}
class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
      c,
    ) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }
}
