import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webhooks',
  templateUrl: './webhooks.component.html',
  styleUrls: ['./webhooks.component.css']
})
export class WebhooksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
