import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService } from 'src/app/core/services/authentication.service'

@Component({
  selector: 'app-main-layout-navbar',
  templateUrl: './main-layout-navbar.component.html',
  styleUrls: ['./main-layout-navbar.component.css'],
})
export class MainLayoutNavbarComponent implements OnInit {
  userFullName: string = ''
  enterpriseName: string = ''
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.userFullName =
      localStorage.getItem('name')?.toString()?.trim()?.length == 0
        ? 'Dispatcher Name'
        : localStorage.getItem('name')

        this.enterpriseName =
        localStorage.getItem('enterpriseName')?.toString()?.trim()?.length == 0
          ? ''
          : localStorage.getItem('enterpriseName')
  }
  logout() {
    this.authenticationService.logout().subscribe((data) => {
      this.router.navigate(['authentication/login'])
    })
  }
}
