import { Injectable } from '@angular/core'
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { Token } from './../models/'
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private login_url = '/api/Account/Login'
  private is_token_valid_url = '/api/Account/IsTokenValid'
  private logout_url = '/api/Account/Logout'

  constructor(private httpClient: HttpClient) {}

  login(username: string, password: string): Observable<any> {
    localStorage.setItem('role', '')
    localStorage.setItem('name', '')
    localStorage.setItem('enterpriseName', '')
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'en', //this.i18nService.language.split("-")[0],
      }),
    }
    return this.httpClient
      .post(
        `${this.login_url}`,
        { UserName: username, Password: password },
        headers,
      )
      .pipe(
        tap((result: Token) => {
          localStorage.setItem('role', result.role)
          localStorage.setItem('name', result.userFullName)
          localStorage.setItem('enterpriseName', result.enterpriseName)
        }),
        catchError((error) => {
          let errorMsg: string
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`
          } else {
            errorMsg = this.getServerErrorMessage(error)
          }
          throw new Error(error.error)
        }),
      )
  }
  logout(): Observable<boolean> {
    localStorage.setItem('role', '')
    localStorage.setItem('name', '')
    localStorage.setItem('enterpriseName', '')
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    }
    return this.httpClient.get<boolean>(`${this.logout_url}`, headers).pipe(
      catchError((error) => {
        let errorMsg: string
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`
        } else {
          errorMsg = this.getServerErrorMessage(error)
        }
        throw new Error(errorMsg)
      }),
    )
  }
  isTokenValid(): Observable<boolean> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    }
    return this.httpClient
      .get<boolean>(`${this.is_token_valid_url}`, headers)
      .pipe(
        tap((result) => {
          return result
        }),
        catchError((error) => {
          let errorMsg: string
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`
          } else {
            errorMsg = this.getServerErrorMessage(error)
          }
          throw new Error(errorMsg)
        }),
      )
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
      case 404: {
        return `Not Found: ${error.message}`
      }
      case 403: {
        return `Access Denied: ${error.message}`
      }
      case 500: {
        return `Internal Server Error: ${error.message}`
      }
      default: {
        return `Unknown Server Error: ${error.message}`
      }
    }
  }
}
