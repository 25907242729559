import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent implements OnInit {
  title = 'webhook'
  showNavContainer: boolean = true

  constructor( private router: Router,
    private authenticationService: AuthenticationService) {
      this.authenticationService.isTokenValid().subscribe((data) => {
        if (!data) this.logout()
      })
     }

  ngOnInit(): void {
  }
  // toggleClick() {
  //   this.showNavContainer = !this.showNavContainer
  // }
  // onWindowClick(e) {
  //   if (e.target.id.toString().toLowerCase().startsWith('sidebar')) return
  //   if (window.screen.width >= 320 && window.screen.width < 768) {
  //     this.showNavContainer = false
  //   }
  // }
  logout() {
    this.authenticationService.logout().subscribe((data) => {
      this.router.navigate(['authentication/login'])
    })
  }
}
